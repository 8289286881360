@use 'theme';

@function getTranslate($factor) {
  @return translate3d(calc(var(--vector-x, 0) * $factor), calc(var(--vector-y, 0) * $factor), 0);
}

@keyframes move {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 0;
  }
  10% {
    opacity: var(--opacity, 1);
  }
  20% {
    transform: getTranslate(0.2) scale(var(--twinkle, 1));
  }
  40% {
    transform: getTranslate(0.4) scale(1);
  }
  60% {
    transform: getTranslate(0.6) scale(var(--twinkle, 1));
  }
  80% {
    transform: getTranslate(0.8) scale(1);
  }
  90% {
    opacity: var(--opacity, 1);
  }
  100% {
    transform: translate3d(var(--vector-x, 0), var(--vector-y, 0), 0) scale(var(--twinkle, 1));
    opacity: 0;
  }
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.dot {
  position: absolute;
  top: var(--position-y, 0);
  left: var(--position-x, 0);
  width: var(--size, 0);
  height: var(--size, 0);
  opacity: var(--opacity, 1);
  border-radius: 50%;
  filter: blur(var(--blur, 0));
  background-color: var(--color, theme.get('activeColor'));
  animation: move var(--life-time, 5000ms) linear infinite;
}
