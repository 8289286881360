@use 'theme';
@use 'gradients';
@use 'breakpoints';
@use 'ripple';
@use 'transitions';
@use 'borders';
@use 'utils/range' as *;

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.925);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.925);
  }
  100% {
    transform: scale(1);
  }
}

.container {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-areas: none;
  gap: theme.spacing(3);
  @each $i in range(1, 7) {
    & > a:nth-of-type(#{$i}) {
      grid-area: auto;
      @include breakpoints.up('small') {
        grid-area: tile-#{$i};
      }
    }
  }

  @include breakpoints.up('small') {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, minmax(1fr, 203px));
    grid-template-areas: "tile-1 tile-1"
                         "tile-1 tile-1"
                         "tile-2 tile-3"
                         "tile-2 tile-3"
                         "tile-4 tile-5"
                         "tile-6 tile-7";
  }

  @include breakpoints.up('large') {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, minmax(1fr, 203px));
    grid-template-areas: "tile-1 tile-1 tile-2"
                         "tile-1 tile-1 tile-2"
                         "tile-3 tile-4 tile-5"
                         "tile-3 tile-6 tile-7";
  }
}
// .tilt {
//   transform-style: preserve-3d;
// }
.tile {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  height: 100%;
  border-radius: borders.radius('lg');
  padding: theme.spacing(3);
  cursor: pointer;
  // transform-style: preserve-3d;
  transition: transitions.get(('box-shadow'));

  & .image {
    width: 35%;
  }

  & .content {
    width: 65%;
  }

  & .imageInner {
    justify-content: center;
    margin-left: theme.spacing(2);
    margin-right: theme.spacing(-4);
  }

  &:hover {
    box-shadow: theme.get('tileBoxShadow');
  }

  @include breakpoints.up('small') {
    padding: theme.spacing(4, 5);
  }
}

.hideOnMobile {
  display: none;

  @include breakpoints.up('small') {
    display: flex;
  }
}

.dots {
  position: absolute;
  top: 0;
  left: 25%;
  display: none;
  width: 25%;
  height: 100%;
  mask-image: radial-gradient(ellipse at 50% 50%, theme.palette('black') 0%, transparent 75%);

  @include breakpoints.up('small') {
    display: block;
  }
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  // transform: translateZ(${imageParalax}px) scale(0.99);
}

.imageInner {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  overflow: visible;
  height: 100px;
  margin-left: theme.spacing(0);
  margin-right: theme.spacing(2);
  z-index: 1;

  & img {
    max-width: 172px;
    max-height: 100px;

    @include breakpoints.up('small') {
      max-width: initial;
      max-height: initial;
    }
  }

  @include breakpoints.up('small') {
    margin-left: theme.spacing(-1);
    margin-right: theme.spacing(4);
    max-width: 100px;
  }
}

.largeImage {
  justify-content: center;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: borders.radius('lg');
  overflow: hidden;
  z-index: 0;
}

.largeBackground {
  position: absolute;
  top: 0;
  left: -16px;
  display: none;
  align-items: center;
  justify-content: center;
  height: 100%;

  & svg {
    fill: theme.palette('blue.100');
  }

  @include breakpoints.up('small') {
    display: flex;
  }
}

.large {
  @include breakpoints.up('small') {
    flex-direction: row;
    padding: theme.spacing(5);

    & .image, & .content {
      width: 50%;
    }

    & .imageInner {
      margin: theme.spacing(0);
    }
  }
}

.vertical {
  @include breakpoints.up('small') {
    padding: theme.spacing(5);
  }

  & .image:not(.smallImage) {
    @include breakpoints.up('small') {
      display: none;
    }
  }

  & .smallImage {
    width: 100%;
    // transform: translateZ(${smallImageParalax}px) scale(0.99);

    @include breakpoints.up('medium') {
      width: 35%;
    }
  }

  & .imageInner {
    @include breakpoints.up('small') {
      margin: theme.spacing(3, 0, 4);
    }

    @include breakpoints.up('medium') {
      justify-content: flex-end;
      margin: theme.spacing(0, 4, 0, 2);
    }

    @include breakpoints.up('large') {
      margin: theme.spacing(0, 4, 0, 0);
    }
  }

  & .itemsWrapper {
    flex-direction: column;
    width: 100%;

    @include breakpoints.up('xSmall') {
      flex-direction: row;
    }

    @include breakpoints.up('small') {
      flex-direction: column;
    }

    @include breakpoints.up('medium') {
      flex-direction: row;
    }
  }
}
.small {
  // & .image {
  //   transform: translateZ(${smallImageParalax}px) scale(0.99);
  // }

  // & .button {
  //   transform: translateZ(${smallButtonParalax}px) scale(0.99);
  // }

  & .pulseImage svg {
    margin: theme.spacing(-1.25);
  }

  & .rotateImage svg {
    margin: theme.spacing(-2.5);
  }

  &:hover .rotateImage svg {
    animation: rotate 15s linear infinite forwards;
  }

  &:hover .pulseImage svg {
    animation: pulse 5s linear infinite forwards;
  }

  @include breakpoints.up('small') {
    padding: theme.spacing(2.5, 5);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  z-index: 1;
  // transform-style: preserve-3d;
  // transform: translateZ(${contentParalax}px) scale(0.99);
}

.standard {
  background-color: theme.get('standardProductBackground');

  &.large {
    background: theme.get('standardProductBackgroundGradient');
  }
}

.standardPlus {
  background-image: gradients.get('gray');
}

.title {
  line-height: 24px;
}

.type {
  margin-top: theme.spacing(0.5);
  color: theme.get('darkerActiveColor');
  line-height: 18px;
}

.description {
  display: none;
  margin-top: theme.spacing(1);
  flex: 1;
  line-height: 18px;

  @include breakpoints.up('small') {
    display: block;
    line-height: 21px;
  }
}

.showDescription {
  display: block;
}

.smallDescription {
  display: block;
  margin-top: theme.spacing(1);
  flex: 1;
  line-height: 18px;

  @include breakpoints.up('small') {
    display: none;
  }
}

.itemsWrapper {
  display: none;
  flex-direction: row;
  margin-top: theme.spacing(2);
  min-height: 152px;
  // transform-style: preserve-3d;
  // transform: translateZ(${contentParalax}px) scale(0.99);

  @include breakpoints.up('small') {
    display: flex;
  }
}

.items {
  display: flex;
  flex-direction: column;
  gap: theme.spacing(1);
  // transform: translateZ(${-contentParalax}px) scale(1.01);
}

.item {
  display: flex;
  align-items: center;
  min-height: 24px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;

  & picture {
    display: flex;
    align-items: center;
  }

  & img {
    height: 100%;
  }
}

.itemText {
  flex: 1;
  margin-left: theme.spacing(2);
}

.button {
  display: none;
  align-items: center;
  justify-content: center;
  margin-top: theme.spacing(3);
  border-radius: borders.radius('xLg');
  height: 30px;
  padding: theme.spacing(0, 3);
  // transform: translateZ(${buttonParalax}px) scale(0.99);
  transition: transitions.get(('color', 'background-color'));
  @include ripple.ripple-effect;
  --rippleColor: #{theme.palette('white')};

  @include breakpoints.up('small') {
    display: inline-flex;
  }
}

.standardButton {
  background-color: theme.get('standardProductColor');
  color: theme.palette('white');

  &:hover {
    background-color: theme.get('activeColor');
  }
}

.standardPlusButton {
  background-color: theme.get('standardPlusProductColor');
  color: theme.palette('white');

  &:hover {
    background-color: theme.palette('black');
  }
}
